var $ = window.jQuery; // DOCUMENT READY

$(function () {
  $('a.pageScroll').on('click', function () {
    var targetDiv = $(this).attr('href');
    $('html, body').animate({
      scrollTop: $(targetDiv).offset().top
    }, 1000);
    return false;
  });
});

if ($('.gform_confirmation_message').length) {
  $('html, body').animate({
    scrollTop: $('.gform_confirmation_message').offset().top
  }, 1000);
}

function isScrolledIntoView(elem) {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();
  var elemTop = $(elem).offset().top;
  return elemTop <= docViewBottom;
}

function onScroll() {
  var srcolledWindow = $(window).scrollTop();

  if (srcolledWindow > 50) {
    $('.navBar').addClass('scroll');
  } else {
    $('.navBar').removeClass('scroll');
  }

  $('.visible').each(function () {
    var $this = $(this);

    if (isScrolledIntoView(this) === true) {
      $this.addClass('alive');
    } else {
      $this.removeClass('alive');
    }
  });
}

$(window).on('scroll', onScroll);

function onLoad(e) {
  $(window).trigger('scroll');
}

$(window).on('load', onLoad);